/* eslint-disable @typescript-eslint/no-unused-vars */
/* global console setInterval, clearInterval */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getFirestore, collection, getDocs, getDoc, doc } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyBE4jvTTaRNKN0iXNd_B_AP4Xrwl4EXqYs",
  authDomain: "dedupeone.firebaseapp.com",
  databaseURL: "https://dedupeone-default-rtdb.firebaseio.com",
  projectId: "dedupeone",
  storageBucket: "dedupeone.appspot.com",
  messagingSenderId: "939637555230",
  appId: "1:939637555230:web:615611b6fa04962e56ae0f",
  measurementId: "G-JDNL35CYD1",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*This is the Fuzzylookup function for appscript we need to change it into App source Javascript */

/**
 * FUZZYLOOOKUP SHEETS FUNCTION
 * vlookup with similarity capabilities
 * @param {any[][]} value The value to search for If omitted, FUZZYLOOKUP returns blank cells it finds in lookup_array.
 * @param {any[][]} column_to_match The column to search the lookup value
 * @param {any[][]} columns_to_retrive The column or columns to return once we find a match
 * @param {number} threshold The minimum value of text similarity btwn lookup value and lookup array
 * @return {string[][]} result
 * @customfunction
 */
function FUZZYLOOKUP(value, column_to_match, columns_to_retrive, threshold) {
  function todayDate() {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd;
    console.log(today);
    return today;
  }
  function datecheck(subscription_ending_date) {
    var today_date = new Date(todayDate());
    var subscription_end_date = new Date(subscription_ending_date);
    return subscription_end_date.getTime() > today_date.getTime();
  }
  function compareTwoStrings(first, second) {
    first = first.toString().replace(/\s+/g, "");
    second = second.toString().replace(/\s+/g, "");

    if (first === second) return 1;
    if (first.length < 2 || second.length < 2) return 0;
    let firstBigrams = new Map();
    for (let i = 0; i < first.length - 1; i++) {
      if (first[i] !== "") {
        const bigram = first.substring(i, i + 2);
        const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;

        firstBigrams.set(bigram, count);
      }
    }

    let intersectionSize = 0;
    for (let i = 0; i < second.length - 1; i++) {
      if (second[i] !== "") {
        const bigram = second.substring(i, i + 2);
        const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;

        if (count > 0) {
          firstBigrams.set(bigram, count - 1);
          intersectionSize++;
        }
      }
    }

    return (2.0 * intersectionSize) / (first.length + second.length - 2);
  }
  function getsub() {
    // eslint-disable-next-line no-async-promise-executor, no-unreachable
    return new Promise(async function (resolve) {
      try {
        var today = new Date();
        var dd = today.getDate();

        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
          dd = "0" + dd;
        }

        if (mm < 10) {
          mm = "0" + mm;
        }
        today = yyyy + "-" + mm + "-" + dd;
        var todayDate = today;
        // eslint-disable-next-line no-undef
        var subCheckedForDate = localStorage.getItem("scfd");
        // eslint-disable-next-line no-undef
        var subStatusToday = localStorage.getItem("ss");
        if (subCheckedForDate && subCheckedForDate == todayDate && subStatusToday == "true") {
          resolve(true);
        } else {
          // eslint-disable-next-line no-undef
          localStorage.setItem("scfd", todayDate);
          // eslint-disable-next-line no-undef
          var email = localStorage.getItem("email");
          var emailFound;
          var subscription_remaining;
          const docRef = doc(db, "payments", "excelfuzzylookup");
          const docSnap = await getDoc(docRef);
          const data = docSnap.data();
          const emails = Object.keys(data);
          const dates = Object.values(data);
          for (var i = 0; i < emails.length; i++) {
            if (email.toLowerCase() == emails[i].toLowerCase()) {
              emailFound = true;
              for (var j = 0; j < dates.length; i++) {
                subscription_remaining = datecheck(dates[i]);
                break;
              }
              break;
            } else {
              emailFound = false;
              resolve(false);
            }
          }
          if (emailFound == true) {
            if (subscription_remaining) {
              // eslint-disable-next-line no-undef
              localStorage.setItem("ss", "true");
              resolve(true);
            } else {
              // eslint-disable-next-line no-undef
              localStorage.setItem("ss", "false");
              resolve(false);
            }
          }
        }
      } catch (exception) {
        resolve(JSON.stringify("Subscription Check not Working!!! Check Your Internet Connection"));
      }
    });
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async function (resolve) {
    try {
      // eslint-disable-next-line no-undef
      var email = localStorage.getItem("email");
      if (email) {
        if (!Array.isArray(value)) {
          value = [[value]];
        }
        var return_array = [];

        value.map(function (v) {
          if (v != "") {
            var accArr = [];
            /*Take note we do a loop using the compareTwoStrings functions*/
            for (var i = 0; i < column_to_match.length; ++i) {
              accArr.push([column_to_match[i][0], compareTwoStrings(column_to_match[i][0], v)]);
            }
            // resolve(JSON.stringify(accArr));
            var scoreArr = accArr.map((elem) => elem[1]);
            // resolve(JSON.stringify(accArr));
            if (Math.max(...scoreArr) > threshold) {
              /*Take note we get the max matching one*/
              var score = Math.max(...scoreArr);
              var calculatedIndex = scoreArr.indexOf(score);
              var temp = [];
              temp.push(score);
              columns_to_retrive[calculatedIndex].map(function (val) {
                temp.push(val);
              });
              return_array.push(temp);
            } else {
              return_array.push(["0", ""]);
            }
          }
        });
        /*get sub is a function were using to check if the firestore customer has already paid for the app*/
        /*We will re-use what we had on translate App*/
        var sub = getsub();
        var sub_status;
        sub.then(async function (param) {
          sub_status = param;
          // eslint-disable-next-line no-undef
          if (sub_status == true || parseInt(localStorage.getItem("tries")) <= 10) {
            // eslint-disable-next-line no-undef
            var tries = parseInt(localStorage.getItem("tries"));
            // eslint-disable-next-line no-undef
            localStorage.setItem("tries", ++tries);
            /*if customer has paid we return the whole array*/
            resolve(return_array);
          } else {
            /*if the customer has not paid we slice the array and return only subset of the array*/
            // eslint-disable-next-line no-undef
            tries = parseInt(localStorage.getItem("tries"));
            // eslint-disable-next-line no-undef
            localStorage.setItem("tries", ++tries);
            var rs = return_array.slice(0, 5);
            rs.push(["• Only Top 5 records shown.Please subscribe to enjoy unlimited records lookups.", ""]);
            rs.push(["• To subscribe, click on Extensions menu above then click on Fuzzy Lookup for Sheets.", ""]);
            rs.push(["• If no records were shown in the top 20 above please lower the threshold", ""]);
            rs.push(["• for assistance contact us on smallpdfkenya@gmail.com", ""]);
            // resolve(JSON.stringify(rs));
            resolve(rs);
          }
        });
      } else {
        let msg = [["Input Email in Taskpane to run formula"]];
        resolve(msg);
      }
    } catch (exception) {
      let msg = [["Do not Select Complete Columns!! Give Specific Column Number"]];
      resolve(msg);
    }
  });
}

/*This is the FUZZYMATCHARRAY function for appscript we need to change it into App source Javascript*/
/**
 * FUZZYMATCHARRAY FUNCTION
 * Text similarity for two cells in bulk
 * @param {any[][]} column1 The first column to compare against the second column.
 * @param {any[][]} column2 The second column to compare against the second column.
 * @return {string[][]} result
 * @customfunction
 */
function FUZZYMATCHARRAY(column1, column2) {
  function todayDate() {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd;
    console.log(today);
    return today;
  }
  function datecheck(subscription_ending_date) {
    var today_date = new Date(todayDate());
    var subscription_end_date = new Date(subscription_ending_date);
    return subscription_end_date.getTime() > today_date.getTime();
  }
  function compareTwoStrings(first, second) {
    first = first.toString().replace(/\s+/g, "");
    second = second.toString().replace(/\s+/g, "");

    if (first === second) return 1;
    if (first.length < 2 || second.length < 2) return 0;

    let firstBigrams = new Map();
    for (let i = 0; i < first.length - 1; i++) {
      const bigram = first.substring(i, i + 2);
      const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;

      firstBigrams.set(bigram, count);
    }

    let intersectionSize = 0;
    for (let i = 0; i < second.length - 1; i++) {
      const bigram = second.substring(i, i + 2);
      const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;

      if (count > 0) {
        firstBigrams.set(bigram, count - 1);
        intersectionSize++;
      }
    }

    return (2.0 * intersectionSize) / (first.length + second.length - 2);
  }
  function getsub() {
    // eslint-disable-next-line no-async-promise-executor, no-unreachable
    return new Promise(async function (resolve) {
      try {
        var today = new Date();
        var dd = today.getDate();

        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
          dd = "0" + dd;
        }

        if (mm < 10) {
          mm = "0" + mm;
        }
        today = yyyy + "-" + mm + "-" + dd;
        var todayDate = today;
        // eslint-disable-next-line no-undef
        var subCheckedForDate = localStorage.getItem("scfd");
        // eslint-disable-next-line no-undef
        var subStatusToday = localStorage.getItem("ss");
        if (subCheckedForDate && subCheckedForDate == todayDate && subStatusToday == "true") {
          resolve(true);
        } else {
          // eslint-disable-next-line no-undef
          localStorage.setItem("scfd", todayDate);
          // eslint-disable-next-line no-undef
          var email = localStorage.getItem("email");
          var emailFound;
          var subscription_remaining;
          const docRef = doc(db, "payments", "excelfuzzylookup");
          const docSnap = await getDoc(docRef);
          const data = docSnap.data();
          const emails = Object.keys(data);
          const dates = Object.values(data);
          for (var i = 0; i < emails.length; i++) {
            if (email.toLowerCase() == emails[i].toLowerCase()) {
              emailFound = true;
              for (var j = 0; j < dates.length; i++) {
                subscription_remaining = datecheck(dates[i]);
                break;
              }
              break;
            } else {
              emailFound = false;
              resolve(false);
            }
          }
          if (emailFound == true) {
            if (subscription_remaining === true) {
              // eslint-disable-next-line no-undef
              localStorage.setItem("ss", "true");
              resolve(true);
            } else {
              // eslint-disable-next-line no-undef
              localStorage.setItem("ss", "false");
              resolve(false);
            }
          }
        }
      } catch (exception) {
        resolve(JSON.stringify("Subscription Check not Working!!! Check Your Internet Connection"));
      }
    });
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async function (resolve) {
    try {
      var backup = [];
      if (column1.length <= 0 || column2.length <= 0) {
        resolve([[""]]);
      } else {
        for (var i = 0; i < column1.length; i++) {
          backup.push(compareTwoStrings(column1[i], column2[i]).toFixed(4));
        }
      }
      var sub = getsub();
      var sub_status;
      sub.then(function (param) {
        sub_status = param;
        // eslint-disable-next-line no-undef
        if (sub_status == true || parseInt(localStorage.getItem("tries")) <= 10) {
          // eslint-disable-next-line no-undef
          var tries = parseInt(localStorage.getItem("tries"));
          // eslint-disable-next-line no-undef
          localStorage.setItem("tries", ++tries);
          //var rsfinal= backup[i]
          // resolve(backup);
          let data = [];
          backup.forEach((element) => {
            data.push([element]);
          });
          if (data.length == 0) data.push([0]);
          resolve(data);
          //return compareTwoStrings(column1,column2).toFixed(4)
        } else {
          // eslint-disable-next-line no-undef
          tries = parseInt(localStorage.getItem("tries"));
          // eslint-disable-next-line no-undef
          localStorage.setItem("tries", ++tries);
          var rs = backup.splice(0, 20);
          rs.push("• Only Top 20 records shown.Please subscribe to enjoy unlimited FuzzyMatch records.");
          rs.push("• To subscribe, click on Extensions menu above then click on Fuzzy Lookup for Sheets.");
          rs.push("• for assistance contact us on smallpdfkenya@gmail.com");
          // resolve(rs);
          let data = [];
          rs.forEach((element) => {
            data.push([element]);
          });
          resolve(data);
          // return "your subscription ended or not subscribed";
        }
      });
    } catch (exception) {
      resolve([["Selected Columns not equal"]]);
    }
  });
}

CustomFunctions.associate("FUZZYLOOKUP", FUZZYLOOKUP);
CustomFunctions.associate("FUZZYMATCHARRAY", FUZZYMATCHARRAY);